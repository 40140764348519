export default {
  state: () => {
    return {
      currencies: {}
    }
  },
  mutations: {
    setCurrencies (state, payload) {
      state.currencies = payload
    }
  },
  actions: {
    global () {
      return this.$api.buyerAccount.dashboard.global()
    },
    local () {
      return this.$api.buyerAccount.dashboard.local()
    },
    localSocket () {
      return this.$api.buyerAccount.dashboard.localSocket()
    },
    globalTest () {
      return this.$api.buyerAccount.dashboard.globalTest()
    },
    localTest () {
      return this.$api.buyerAccount.dashboard.localTest()
    },
    history (_, body) {
      return this.$api.buyerAccount.dashboard.history(body)
    },
    async cba ({ commit }) {
      const data = await this.$api.buyerAccount.dashboard.cba()
      commit('setCurrencies', data)
      return data
    }
  },
  namespaced: true
}
