export default {
  state: () => {
    return {
      about_us: null,
      members: null
    }
  },

  mutations: {
    setMembers (state, payload) {
      state.about_us = payload
    },
    setAbout (state, payload) {
      state.members = payload
    }
  },

  actions: {
    async register (_, sendData) {
      try {
        return await this.$api.aja.register(sendData)
      } catch (e) {
        console.error(e)
      }
    },
    async getAbout ({ commit }) {
      const { data } = await this.$api.aja.getAbout()
      commit('setAbout', data)
    },
    async getMembers ({ commit }) {
      const data = await this.$api.aja.getMembers()
      commit('setMembers', data)
    }
  },
  namespaced: true
}
